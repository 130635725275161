import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

const RootPage: React.FC = () => {
    const router = useRouter()

    useEffect(() => {
        router.push('/list')
    }, [router])

    return <></>
}

export default RootPage
